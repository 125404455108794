<template>

  <div>

    <cliente-add-new
      :is-add-new-cliente-sidebar-active.sync="isAddNewClienteSidebarActive"
      :ruta-options="rutaOptions"
    />

    <!-- <pedido-add-new
      :is-add-new-pedido-sidebar-active.sync="isAddNewPedidoSidebarActive"
      :ruta-options="rutaOptions"
      :clientes-list="items"
    /> -->
    <!-- @refetch-data="refetchData" -->
    <!-- Filters -->
    <!-- <users-list-filters
      :ruta-filter.sync="rutaFilter"
      :ruta-options="rutaOptions"
    /> -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>ruta</label>
            <v-select
              v-model="rutaFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rutaOptions"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries of {{ itemsFiltereds.total }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                :disabled="false"
                @click="isAddNewClienteSidebarActive = true"
              >
                <span class="text-nowrap">Add Client</span>
              </b-button>
              <!-- <b-button
                variant="secondary"
                class="ml-2"
                :disabled="false"
                @click="isAddNewPedidoSidebarActive = true"
              >
                <span class="text-nowrap">Add Order</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="itemsFiltereds.users"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <!-- <template #cell(item)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.avatar"
                :text="avatarText(data.fullName)"
                :variant="`light-${resolveUserrutaVariant(data.ruta)}`"
                :to="{ name: 'apps-users-view', params: { id: data.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'bimbo-clientes-edit', params: { id: data.item.cliente_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.cliente_id }}
            </b-link>
          </b-media>
        </template> -->

        <!-- Column: id -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'bimbo-clientes-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.id }}
            </b-link>
          </div>
        </template>

        <!-- Column: ruta -->
        <template #cell(ruta)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :icon="resolveUserrutaIcon(data.ruta)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserrutaVariant(data.ruta)}`"
            /> -->
            <span class="text-right font-weight-bolder">{{ data.item.ruta }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(dir_literal)="data">
          <!-- <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.dir_literal)}`"
            class="text-capitalize"
          >
            {{ data.dir_literal }}
          </b-badge> -->
          <span class="align-text-top text-capitalize">{{ data.item.dir_literal }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="itemsFiltereds.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <mapa-basico
      :markers="itemsFiltereds.users"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BCardHeader, BCardBody,
  BDropdown, BDropdownItem, BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { paginateArray, sortCompare } from '@/libs/filter-data'
import ClienteAddNew from '@/views/bimbo/components/ClienteAddNew.vue'
// import PedidoAddNew from '@/views/bimbo/components/PedidoAddNew.vue'
import MapaBasico from '@/views/bimbo/components/MapaBasico.vue'

export default {
  components: {
    ClienteAddNew,
    // PedidoAddNew,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    vSelect,
    MapaBasico,
  },
  data() {
    return {
      isAddNewClienteSidebarActive: false,
      isAddNewPedidoSidebarActive: false,
      rutaOptions: [
        { label: 'Ruta 0', value: '0' },
        { label: 'Ruta 1', value: '1' },
        { label: 'Ruta 2', value: '2' },
      ],
      tableColumns: [
        { key: 'id', sortable: true },
        { key: 'nombre', sortable: true },
        { key: 'dir_literal', sortable: true },
        { key: 'ruta', sortable: true },
        { key: 'hub', sortable: true },
        { key: 'actions' },
      ],
      rutaFilter: null,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
    }
  },

  computed: {
    items() {
      return this.$store.state.clientes.clientes
    },
    itemsFiltereds() {
      const {
        rutaFilter,
        perPage,
        currentPage,
        searchQuery,
        sortBy,
        isSortDirDesc,
      } = this.$data
      let clients = this.items
      // if (rutaFilter) clients = clients.filter(c => c.ruta === rutaFilter)
      const queryLowered = searchQuery.toLowerCase()
      clients = clients.filter(
        cliente =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (cliente.id.toLowerCase().includes(queryLowered) || cliente.nombre.toLowerCase().includes(queryLowered) || cliente.dir_literal.toLowerCase().includes(queryLowered) || cliente.hub.toLowerCase().includes(queryLowered)) && cliente.ruta.toString() === (rutaFilter?.value || cliente.ruta.toString()),
      )
      /* eslint-enable  */

      const sortedData = clients.sort(sortCompare(sortBy))
      if (isSortDirDesc) sortedData.reverse()

      return {
        users: paginateArray(sortedData, perPage, currentPage),
        total: clients.length,
      }
    },
    // clientes() {
    //   const consulta = {
    //     ruta: this.rutaFilter, perPage: this.perPage, currentPage: this.currentPage, q: this.searchQuery, sortBy: this.sortBy, sortDesc: this.isSortDirDesc,
    //   }
    //   return this.$store.getters['clientes/clientes'](consulta)
    // },
  },

  created() {
    this.fetchClientes()
  },

  beforeDestroy() {
    // this.$store.dispatch('clientes/UNSUSCRIBE_CLIENTES')
  },

  methods: {
    fetchClientes() {
      this.$store.dispatch('clientes/getClientes')
    },

    refetchData() {
      // console.log('vale, entendido...')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
