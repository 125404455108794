<template>
  <b-card no-body>
    <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-circle
        v-if="false"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
      <l-marker
        :lat-lng="markerLatLng"
        :icon="iconHome"
      >
        <l-popup>CargoBici</l-popup>
      </l-marker>

      <!-- <l-marker
        :lat-lng="ruta.latlngs[1]"
        :icon="iconBlue"
      >
        <l-popup>Punto 1</l-popup>
      </l-marker> -->

      <l-marker
        v-for="(marker, n) in markers"
        :key="n"
        :lat-lng="[marker.lat, marker.lng, { draggable: 'true' }]"
        :icon="getIcon(marker.ruta)"
      >
        <l-popup>{{ marker.nombre }}</l-popup>
      </l-marker>
      <l-polyline
        v-if="false"
        :lat-lngs="ruta.latlngs"
        :color="ruta.color"
        line-cap="<path d='M0,0 V4 L2,2 Z' fill='red' />"
        :opacity="0.7"
      >
        <l-popup content="polyline" />
      </l-polyline>
    </l-map>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { BCard } from 'bootstrap-vue'
import {
  LMap, LTileLayer, LCircle, LMarker, LPopup, LPolyline,
} from 'vue2-leaflet'
import { Icon, icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import gmap from '@/@fake-db/data/mock_google.json'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    LPolyline,
    BCard,
  },
  props: {
    markers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gmapGoogle: gmap,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 14,
      center: [41.4169, 2.20858],
      markerLatLng: [41.4169, 2.20858, { draggable: 'true' }],
      circle: {
        center: [41.4168037, 2.2066069],
        radius: 1000,
        color: '#EA5455',
      },
      iconHome: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_home.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconDefault: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_yellow.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconBlue: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_blue.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconGreen: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_green.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconPurple: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_purple.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconRed: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_red.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconYellow: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_yellow.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      icons: {
        blue: {
          iconUrl: require('@/assets/images/icons/maps/marker_blue.svg'),
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        },
        green: {
          iconUrl: require('@/assets/images/icons/maps/marker_green.svg'),
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        },
        purple: {
          iconUrl: require('@/assets/images/icons/maps/marker_purple.svg'),
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        },
        red: {
          iconUrl: require('@/assets/images/icons/maps/marker_red.svg'),
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        },
        yellow: {
          iconUrl: require('@/assets/images/icons/maps/marker_yellow.svg'),
          iconSize: [24, 24],
          iconAnchor: [12, 12],
        },
      },
    }
  },
  computed: {
    lc() {
      return `<path
        d='M0,0 V4 L2,2 Z' 
        fill='red' 
        />`
    },
    ruta() {
      const pathObj = gmap.overview_path
      const pathArr = Object.keys(pathObj).map(k => [pathObj[k].lat, pathObj[k].lng])
      const polyline = {
        type: 'Ruta 1',
        latlngs: pathArr,
        color: 'red',
      }
      return polyline // gmap.overview_path
    },
  },
  methods: {
    getIcon(ruta) {
      let result = this.iconDefault
      switch (ruta) {
        case 0:
          result = this.iconGreen
          break
        case 1:
          result = this.iconRed
          break
        case 2:
          result = this.iconBlue
          break
        case 3:
          result = this.iconPurple
          break
        default:
          break
      }
      return result
    },
  },
}
</script>

<style lang="scss">
.mk-icon-cli {
  fill: red;
}
.vue2leaflet-map {
  &.leaflet-container{
    height: 450px;
  }
}
</style>
