<template>
  <!-- <b-form-group>
    <b-input
      v-model="direc"
    />
  </b-form-group> -->
  <div>
    <b-form-group
      label="Dirección: Literal"
      label-for="direct"
    >
      <b-form-input
        id="direct"
        v-model="direc"
        placeholder="Dirección literal"
        @input="searchPlaces"
      />
    </b-form-group>
    <b-form-select
      v-if="options.length"
      v-model="selected"
      :options="options"
      :select-size="4"
      @change="select"
    />
    <!-- <b-form-group>
      <v-select
        v-model="selected"
        label="title"
        :options="options"
      />
    </b-form-group> -->
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { places, geo } from '@/libs/google-directions'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    // vSelect,
  },
  setup(props, { emit }) {
    const direc = ref('')
    const selected = ref('')
    const options = ref([])

    const searchPlaces = async () => {
      // Busca dirección por texto:
      if (direc.value.length < 10) { // || direc.value.length > 30
        options.value = []
        return
      }
      const result = await places(direc.value)
      // console.log(result.map(i => ({ text: i.description, value: i.place_id })))
      options.value = result.map(i => ({ text: i.description, value: i.place_id }))
      // selected.value = direc.value
      // options.value = result
      // return result
    }

    const geoCode = async () => {
      // Geolocaliza por placeId (devuelto por el anterior...)
      const result = await geo(selected.value)
      // console.log(result)
      if (result.length) {
        const dir = JSON.parse(JSON.stringify(result[0]))
        // const { location } = dir.geometry
        // const { formatted_address, address_components, place_id } = dir
        // console.log('dir_literal: ', formatted_address)
        // console.log('dir_calle: ', address_components.filter(i => i.types.includes('route')).map(f => f.long_name)[0])
        // console.log('dir_numero: ', address_components.filter(i => i.types.includes('street_number')).map(f => f.long_name)[0])
        // console.log('dir_localidad: ', address_components.filter(i => i.types.includes('locality')).map(f => f.long_name)[0])
        // console.log('dir_cp: ', address_components.filter(i => i.types.includes('postal_code')).map(f => f.long_name)[0])
        // console.log('lat: ', location.lat)
        // console.log('lng: ', location.lng)
        // console.log('place_id: ', place_id)
        emit('directionCalculated', dir)
      }
    }

    const select = () => {
      // console.log(selected.value)
      const result = options.value.filter(i => i.value === selected.value).map(d => d.text)[0]
      // console.log(result)
      direc.value = result
      options.value = []
      geoCode()
    }

    return {
      direc,
      selected,
      options,
      searchPlaces,
      geoCode,
      select,
    }
  },
}
</script>

<style>

</style>
