<template>
  <b-sidebar
    id="add-new-cliente-sidebar"
    :visible="isAddNewClienteSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-cliente-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Client
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            name="ID"
            rules="required"
          >
            <b-form-group
              label="ID"
              label-for="id"
            >
              <b-form-input
                id="id"
                v-model="userData.id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="ID"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="userData.nombre"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nombre"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: Literal -->
          <validation-provider
            v-if="userData.dir_literal"
            #default="validationContext"
            name="Direccion Literal"
            rules="required"
          >
            <b-form-group
              label="Dirección Literal"
              label-for="dir-literal"
            >
              <b-form-input
                id="dir-literal"
                v-model="userData.dir_literal"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Dirección Literal"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <search-places-component
            v-else
            @directionCalculated="directionCalculated"
          />

          <!-- Lat -->
          <validation-provider
            #default="validationContext"
            name="Lat"
            rules="required"
          >
            <b-form-group
              label="Dirección: Latitud"
              label-for="lat"
            >
              <b-form-input
                id="lat"
                v-model="userData.lat"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Lng -->
          <validation-provider
            #default="validationContext"
            name="Lng"
            rules="required"
          >
            <b-form-group
              label="Dirección: Longitud"
              label-for="lng"
            >
              <b-form-input
                id="lng"
                v-model="userData.lng"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: calle -->
          <validation-provider
            #default="validationContext"
            name="Calle"
            rules="required"
          >
            <b-form-group
              label="Dirección: Calle"
              label-for="calle"
            >
              <b-form-input
                id="calle"
                v-model="userData.dir_calle"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: número -->
          <validation-provider
            #default="validationContext"
            name="Numero"
            rules="required"
          >
            <b-form-group
              label="Dirección: Número"
              label-for="numero"
            >
              <b-form-input
                id="numero"
                v-model="userData.dir_numero"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: CP -->
          <validation-provider
            #default="validationContext"
            name="CP"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Dirección: Cód. Postal"
              label-for="cp"
            >
              <b-form-input
                id="cp"
                v-model="userData.dir_cp"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: localidad -->
          <validation-provider
            #default="validationContext"
            name="Localidad"
            rules="required"
          >
            <b-form-group
              label="Dirección: Localidad"
              label-for="localidad"
            >
              <b-form-input
                id="localidad"
                v-model="userData.dir_localidad"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: HUB -->
          <validation-provider
            #default="validationContext"
            name="HUB"
            rules="required|alpha-num"
          >
            <b-form-group
              label="HUB"
              label-for="hub"
            >
              <b-form-input
                id="hub"
                v-model="userData.hub"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Dirección: ruta -->
          <validation-provider
            #default="validationContext"
            name="Ruta"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Ruta"
              label-for="ruta"
            >
              <b-form-input
                id="ruta"
                v-model="userData.ruta"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template v-if="false">
            <b-form-group
              label="Hora Ini"
              label-for="mc-cliente-hora-ini"
            >
              <b-form-timepicker
                id="mc-cliente-hora-ini"
                v-model="hIni"
                v-bind="labels[locale] || {}"
                :locale="locale"
                @context="onContextIni"
              />
            </b-form-group>

            <b-form-group
              label="Hora Fin"
              label-for="mc-cliente-hora-fin"
            >
              <b-form-timepicker
                id="mc-cliente-hora-fin"
                v-model="hFin"
                v-bind="labels[locale] || {}"
                :locale="locale"
                @context="onContextFin"
              />
            </b-form-group>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTimepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
// import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import SearchPlacesComponent from './SearchPlacesComponent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTimepicker,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    SearchPlacesComponent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClienteSidebarActive',
    event: 'update:is-add-new-cliente-sidebar-active',
  },
  props: {
    isAddNewClienteSidebarActive: {
      type: Boolean,
      required: true,
    },
    rutaOptions: {
      type: Array,
      required: true,
    },
    // planOptions: {
    //   type: Array,
    //   required: false,
    // },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countries,
      hIni: null,
      hFin: null,
      locale: 'es-ES',
      labels: {
        es: {
          labelHours: 'Hora',
          labelMinutes: 'Minuto',
          labelSeconds: 'Segundos',
          labelIncrement: 'Más',
          labelDecrement: 'Menos',
          labelSelected: 'Seleeciona',
          labelNoTimeSelected: 'Nada seleccionado',
          labelCloseButton: 'Cerrar',
        },
      },
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      id: '',
      nombre: '',
      lat: '',
      lng: '',
      dir_literal: '',
      dir_calle: '',
      dir_numero: '',
      dir_cp: '',
      dir_localidad: '',
      place_id: '',
      hub: '',
      ruta: '',
      hora_ini: 28800,
      hora_fin: 64800,
      email: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onContextIni = ctx => {
      // this.contextIni = ctx
      userData.value.hora_ini = ctx.hours * 3600 + ctx.minutes * 60
    }

    const onContextFin = ctx => {
      // this.contextIni = ctx
      userData.value.hora_fin = ctx.hours * 3600 + ctx.minutes * 60
    }

    const directionCalculated = dir => {
      const { location } = dir.geometry
      const { formatted_address, address_components, place_id } = dir
      /* eslint-disable camelcase */
      /* eslint-disable prefer-destructuring */
      userData.value.dir_literal = formatted_address
      userData.value.dir_calle = address_components.filter(i => i.types.includes('route')).map(f => f.long_name)[0]
      userData.value.dir_numero = address_components.filter(i => i.types.includes('street_number')).map(f => f.long_name)[0]
      userData.value.dir_localidad = address_components.filter(i => i.types.includes('locality')).map(f => f.long_name)[0]
      userData.value.dir_cp = address_components.filter(i => i.types.includes('postal_code')).map(f => f.long_name)[0]
      userData.value.lat = location.lat
      userData.value.lng = location.lng
      userData.value.place_id = place_id
    }

    const onSubmit = () => {
      userData.value.nombre = userData.value.nombre.toUpperCase()
      userData.value.hub = userData.value.hub.toUpperCase()
      store.dispatch('clientes/addCliente', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-cliente-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onContextIni,
      onContextFin,
      directionCalculated,
    }
  },

  methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
